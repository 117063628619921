// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-affiliates-tsx": () => import("./../src/pages/affiliates.tsx" /* webpackChunkName: "component---src-pages-affiliates-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-and-security-tsx": () => import("./../src/pages/privacy-and-security.tsx" /* webpackChunkName: "component---src-pages-privacy-and-security-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-stripe-close-tsx": () => import("./../src/pages/stripe/close.tsx" /* webpackChunkName: "component---src-pages-stripe-close-tsx" */),
  "component---src-pages-stripe-manage-tsx": () => import("./../src/pages/stripe/manage.tsx" /* webpackChunkName: "component---src-pages-stripe-manage-tsx" */),
  "component---src-pages-stripe-start-tsx": () => import("./../src/pages/stripe/start.tsx" /* webpackChunkName: "component---src-pages-stripe-start-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

